import React from 'react'
import Layout from '../../components/layout'
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from 'react-vertical-timeline-component'
import 'react-vertical-timeline-component/style.min.css'
import MentalHealthIcon from '../../images/icons/mental-health.svg'
import Img from 'gatsby-image/withIEPolyfill'
import ReactWOW from 'react-wow'
import { Link, graphql } from 'gatsby'
export const query = graphql`
  query MethodQuery {
    methodImages: allFile(filter: { relativeDirectory: { eq: "method" } }) {
      edges {
        node {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
class Method extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (<Layout>
      <section className="section-padding-large biq-bg-prim biq-text-white">
        <div className="container">
          <h1>Method</h1>
        </div>
      </section>
      <section className="section-padding biq-bg-gray biq-text-prim">
        <div className="container">
          <div className="text-xl text-center">In our method we focus on the mechanical and psychological. We focus on providing a baseline screening that is functional. We consider your history, experience and beliefs to help make a change in your breathing personal and permanent.</div>
        </div>
      </section>
      <section
        id="methodContent"
        className="section-padding biq-bg-white biq-text-black"
      >
        <div className="container">
          <ReactWOW animation="fadeIn" delay="0.4s">
            <h1 className="biq-text-prim">HOW IT WORKS</h1>
          </ReactWOW>
          <div className="flex flex-col flex-wrap">
            <div style={{ height: '25vh' }} className="flex flex-col-reverse xl:flex-row flex-wrap place-items-center w-full mt-8">
              <ReactWOW animation="fadeInLeft" delay="0.6s">
                <div className="flex flex-col justify-center place-items-center w-9/12 mt-4">
                  <h2 className="text-center biq-text-lg xl:text-3xl font-medium biq-text-prim">
                    <span className="font-extrabold">Step 1</span> - Take the
                      BIQ
                    </h2>
                  <div className="text-md xl:text-xl mt-4 flex w-8/12 justify-center text-center">
                    Establish a baseline with our easy
                    to use screening tool that allows you to assess if you are
                    really taking a deep breath.
                    </div>
                </div>
              </ReactWOW>
              <ReactWOW animation="fadeInRight" delay="0.6s">
                <div className="flex flex-col justify-center place-items-center w-3/12 mt-4">
                  <div class="w-full">
                    <Img fluid={this.props.data.methodImages.edges[0].node.childImageSharp.fluid} />
                  </div>
                </div>
              </ReactWOW>
            </div>
            <div style={{ height: '25vh' }} className="flex flex-col-reverse xl:flex-row-reverse flex-wrap place-items-center w-full mt-8">
              <ReactWOW animation="fadeInRight" delay="0.6s">
                <div className="flex flex-col justify-center place-items-center w-9/12 mt-4">
                  <h2 className="text-center biq-text-lg xl:text-3xl font-medium biq-text-prim">
                    <span className="font-extrabold">Step 2</span> - Finesse
                      the mechanics
                    </h2>
                  <div className="text-md xl:text-xl mt-4 flex w-8/12 justify-center text-center">
                    Make sure you are using the right muscles
                    </div>
                </div>
              </ReactWOW>
              <ReactWOW animation="fadeInLeft" delay="0.6s">
                <div className="flex flex-col justify-center place-items-center w-3/12 mt-4">
                  <div class="w-full">
                    <Img fluid={this.props.data.methodImages.edges[1].node.childImageSharp.fluid} />
                  </div>
                </div>
              </ReactWOW>
            </div>
            <div style={{ height: '25vh' }} className="flex flex-col-reverse xl:flex-row flex-wrap place-items-center w-full mt-8">
              <ReactWOW animation="fadeInLeft" delay="0.6s">
                <div className="flex flex-col justify-center place-items-center w-9/12 mt-4">
                  <h2 className="text-center biq-text-lg xl:text-3xl font-medium biq-text-prim">
                    <span className="font-extrabold">Step 3</span> - Breathing
                      exercies for strong lungs
                    </h2>
                  <div className="text-md xl:text-xl mt-4 flex w-8/12 justify-center text-center">
                    The best way to have “strong lungs” is to strengthen the
                    muscles that make them fill and empty
                    </div>
                </div>
              </ReactWOW>
              <ReactWOW animation="fadeInRight" delay="0.6s">
                <div className="flex flex-col justify-center place-items-center w-3/12 mt-4">
                  <div class="w-full">
                    <Img fluid={this.props.data.methodImages.edges[2].node.childImageSharp.fluid} />
                  </div>
                </div>
              </ReactWOW>
            </div>
          </div>
        </div>
      </section>
      <section
        id="biqMockup"
        className="section-padding biq-bg-gray"
      // style={{
      //   background: `url(${this.props.data.biqBackground.edges[0].node.childImageSharp.fluid.src})`,
      //   backgroundSize: 'cover',
      //   backgroundPosition: 'center',
      //   backgroundBlendMode: 'darken',
      //   backgroundPositionY: '1',
      // }}
      >
        <div className="flex absolute">
          {/* <div className="w-full">
              <Img
                fluid={
                  this.props.data.biqBackground.edges[0].node.childImageSharp
                    .fluid
                }
              />
            </div> */}
        </div>
        <div className="container">
          {/* BIQ  */}
          <div className="flex flex-row flex-wrap my-24">
            {/* <div className="flex w-full xl:w-1/2"> */}
            {/* <div className="w-full">
                  <Img
                    fluid={
                      this.props.data.biqMobile.edges[0].node.childImageSharp
                        .fluid
                    }
                  />
                </div> */}
            {/* </div> */}
            <div className="flex flex-col w-full place-items-center justify-center">
              <h1 className="uppercase biq-text-prim">Take the BIQ</h1>
              <h2 className="flex font-bold font- my-6 biq-text-xl text-center  biq-text-prim">
                You can't change what you don't measure
                </h2>
              <span className="flex font-normal my-6">
                <Link to="/biq" className="btn-prim biq-text-white">
                  Find Out Your BreathingIQ
                  </Link>
              </span>
            </div>
          </div>
        </div>
      </section>
      {/* <section className="section-padding">
        <div className="container">
          <h2 className="biq-text-prim text-5xl">Step One</h2>
          <div className="flex flex-row w-full">
            <div className="flex flex-col w-7/12">
              <div className="biq-text-prim text-3xl text-center mt-16">
                Take the BIQ
        </div>
              <div className=" text-lg mt-10">
                Too often with medical testing, people get “results” but they don’t
                know what the numbers really mean, how they affect their lives
          practically, and—most importantly— how to change them for the better.{' '}
              </div>
              <div className=" text-lg mt-10">
                Keep in mind that the B-IQ is a functional measurement, which means
                that it is practical, useful, and that it intuitively empowers you.
                Yes, you can, and should, take and retake the test, and track your
                progress over time. As your score improves, you can also evaluate the
                impact these changes have on your daily life.
        </div>
              <div className=" text-lg mt-10">
                The beauty of the B-IQ is that it makes sense. When you let your body
                widen on the inhale, it means your diaphragm is working: it’s
                flattening and expanding your thoracic cavity. You are trying not to
                breathe vertically, because that uses “auxiliary” muscles that were
                never meant to be primary breathing muscles.
        </div>
              <div className=" text-lg mt-10">
                The first step in this system is to understand it intellectually
                (i.e., that the above paragraph makes sense in a way that it never has
                before).
        </div>
              <div className=" text-lg mt-10">
                Next comes the ability to visualize the parts of the “machine” and how
                they should be operating and interacting.
        </div>

              <div className=" text-lg mt-10">
                Then comes having the sensation; that is to say, the kinesthetic
                experience of what is right, wrong, good and better. That light bulb,
                that “ah-ha moment,” has to occur several times in the process.
        </div>

              <div className=" text-lg mt-10">
                The simplicity of this instruction makes for a massive reorganization
                of what you previously thought as correct. But listen up, because
                here’s what’s truly interesting: you’ll start looking around you and
                seeing how much misunderstanding there is out there. Watch how people
                mistakenly gesticulate upward when talking about a deep breath!
        </div>
            </div>
            <div className="flex w-5/12">

            </div>
          </div>
        </div>
      </section>
      <section className="section-padding-large biq-bg-prim biq-text-white">
        <div className="container">
          <h2 class="text-5xl">Step Two</h2>
          <div className="flex flex-row-reverse w-full">
            <div className="flex flex-col w-7/12">
              <div className="biq-text-white text-3xl text-center mt-16">
                Finesse the mechanics
            </div>
              <div className=" text-lg mt-10">
                The beauty of the B-IQ is that it makes sense. When you let your body widen on the inhale, it means your diaphragm is working: it’s flattening and expanding your thoracic cavity. You are trying not to breathe vertically, because that uses “auxiliary” muscles that were never meant to be primary breathing muscles.The first step in this system is to understand it intellectually (i.e., that the above paragraph makes sense in a way that it never has before).Next comes the ability to visualize the parts of the “machine” and how they should be operating and interacting.
            </div>
              <div className="text-lg mt-10">
                Then comes having the sensation; that is to say, the kinesthetic experience of what is right, wrong, good and better. That light bulb, that “ah-ha moment,” has to occur several times in the process.The simplicity of this instruction makes for a massive reorganization of what you previously thought as correct.
            </div>
              <div className=" text-lg mt-10">
                Breathing is biomechanical, psychological and biochemical (Dr. Rosalba
                Courtney). All aspects are involved and intertwined.
            </div>
            </div>
            <div className="flex flex-col w-5/12">

            </div>
          </div>
        </div>
      </section>
      <section className="section-padding biq-bg-white biq-text-black">
        <div className="container">
          <h2 class="text-5xl">Step Three</h2>
          <div className="flex w-full">
            <div className="flex flex-col w-7/12">
              <div className="biq-text-prim text-3xl text-center mt-16">
                Breathing Exercises for strong lungs
        </div>
              <div className=" text-lg mt-10">
                The best way to have “strong lungs” is to strengthen the muscles that make them fill and empty—the 10 pounds of inhale and exhale muscles that include the diaphragm, intercostals, and core/ab muscles. Realize that your lungs don’t power themselves; you need to do breathing exercises to keep them healthy.
        </div>
              <div className=" text-lg mt-10">
                Breathing is biomechanical, psychological and biochemical (Dr. Rosalba
                Courtney). All aspects are involved and intertwined.
        </div>
              <div className="xl:ml-auto w-full p-0 xl:p-4 video-height mt-16">
                <iframe
                  width="100%"
                  height="100%"
                  src="https://www.youtube.com/embed/gQfJ2e7hWbk"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>

            </div>
            <div className="flex flex-col w-5/12">

            </div>
          </div>
          <h3 className="text-4xl font-normal mt-8">Things you will learn:</h3>
          <div>
            <ol className="ml-8 mt-6">
              <li>Where most dense rich oxygen part of the lungs is</li>
              <li>
                Diaphragm is the size of a frisbee or small pizza that connects
                all the way around your rib cage
            </li>
              <li>You have over 10 pounds of breathing muscle</li>
              <li>
                Your vagus nerve starts at your head and goes down your whole
                body, it can tell when you are breathing with your upper chest or
                belly and tells your brain to make you stressed or to make our
                calm and everything in between
            </li>
              <li>
                The opposite of fighter or flight or freeze is rest and digest. If
                you aren't breathing in rest and digest then your body won't be
                sleeping or digesting well.
            </li>
              <li>
                Your breathing changes at age 5.5 when you start imitating your
                parents, sitting at school and experiencing social and academic
                stress.
            </li>
              <li>
                Your lungs do nothing on their own, they are powered by the
                muscles around the e below them.
            </li>
              <li>Your lung function starts to decline at age 29.</li>
              <li>
                The movement of your diaphragm massages your stomach. The
                diaphragm attaches to your lower back and is responsible for
                keeping your back healthy.
            </li>
              <li>
                When you breathe vertically, you are using auxiliary muscles, neck
                and shoulder muscles that were never meant to be primary breathing
                muscles.
            </li>
              <li>
                When you breathe vertically you have to breathe faster to get the
                air you need since it's a shallower breath by definition.
            </li>
              <li>
                While the belly breath is a beginner breath you have to practice,
                eventually you want an abdominothoracic 360 degree breath both
                your middle and bottom of our ribs expand on the inhale and your
                body narrows on exhale.
            </li>
              <li>
                Breathing vertically makes your heart rate go up, your blood
                pressure goes up and your cortisol (stress hormone) rise. When you
                breathe Horizontally your heart rate, blood pressure and cortisol
                go down and your immune system is supported.
            </li>
              <li>
                The diaphragm flattens on the inhale (and massages your digestive
                organs underneath) and on the exhale our core muscles squeeze your
                body and help you "deflate."
            </li>
              <li>
                The flexibility of your ribcage is critical for good breathing
                throughout your life.
            </li>
            </ol>
          </div>
        </div>
      </section> */}
      {/* <section className="section-padding-large biq-bg-prim biq-text-white">
      <div className="container">
        <div className="xl:ml-auto w-full p-0 xl:p-4 video-height">
          <iframe
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/gQfJ2e7hWbk"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </div>
    </section> */}
    </Layout>)
  }
}

export default Method
